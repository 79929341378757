import React from "react";
import { render } from "react-dom";
import "./index.css";
import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import App from "./App";
import { ApolloProvider } from "@apollo/react-hooks";
import client from "./apolloClient";
import { AuthProvider } from "./context/authContext";
import { BrowserRouter } from "react-router-dom";

const root = document.getElementById("root");
render(
  <AuthProvider>
    <ApolloProvider client={client}>
      <BrowserRouter>
        <React.StrictMode>
          <App />
        </React.StrictMode>
      </BrowserRouter>
    </ApolloProvider>
  </AuthProvider>,
  root
);
